import { Button } from '@mui/material';
import React, { useContext, useEffect, useState} from 'react';
import globalContext from '../context/globalContext';
import PedestalUtility from './pedestal-utility';

const PedestalUtilities = props => {

    const context  = useContext(globalContext);

    const [start, setStart] = useState(0);
    const [stop, setStop] = useState(0);
    const [pause, setPause] = useState(0);
    const [resume, setResume] = useState(0);
    
    const [hasActiveServices, setHasActiveServices] = useState(false);
    const [hasActiveTests, setHasActiveTests] = useState(false);
    const [hasPausedServices, setHasPausedServices] = useState(false);



    //
    //  Button clicks
    //

    const handleToggleStart = () => {

        if(hasActiveTests)
            setStop(stop+1);
        else {
            setStart(start+1);

            // Check the utilities are being listened to
            handleCheckUtilitiesListening();
        }

    }

    const handleClickPause = () => {
        setPause(pause+1);
    }

    const handleClickResume = () => {
        setResume(resume+1);
    }

    const handleCheckUtilitiesListening = () => {
        let id_utilities = [];
        context.utilities.data.filter(u => u.id_pedestal === props.id_pedestal).map(u => id_utilities.push(u.id_utility));
        context.checkRoomForStations(id_utilities);
    }




    //
    //  Monitoring test sessions
    //
    useEffect(() => {


        let hasTests = false;
        context.utilities.data.filter(u => u.id_pedestal === props.id_pedestal).map(u => {
            const idx = context.tests.data.findIndex(t => u.id_utility === t.id_utility && t.ended === null)
            if(idx > -1)
                hasTests = true;
        })
        setHasActiveTests(hasTests);


    }, [context.tests.data])


    //
    //  Monitoring active live sessions
    //
    useEffect(() => {

        let hasServices = false;
        context.utilities.data.filter(u => u.id_pedestal === props.id_pedestal).map(u => {
            const idx = context.services.data.findIndex(s => u.id_utility === s.id_utility)
            if(idx > -1)
                hasServices = true;
        })
        setHasActiveServices(hasServices);

    }, [context.services.data])



    //
    //  Monitoring paused live sessions
    //
    useEffect(() => {

        let hasPausedServices = false;
        context.utilities.data.filter(u => u.id_pedestal === props.id_pedestal).map(u => {

            const idx = context.paused.data.findIndex(p => u.id_utility === p.id_utility && p.resumed === null)
            if(idx > -1)
                hasPausedServices = true;

        })

        setHasPausedServices(hasPausedServices);

    }, [context.paused.data])






    return (
        <React.Fragment>

            <div className="flex-row align-center m-b">
                {hasActiveServices && <Button onClick={handleClickPause} size="small" color="success" variant="contained">Pause Services</Button>}
                {hasPausedServices && <Button onClick={handleClickResume} size="small" color="error" variant="contained" disabled={hasActiveTests}>Resume Services</Button>}
                <div className="flex"></div>
                {hasActiveTests && <Button onClick={handleToggleStart} size="small" color="error" variant="contained">Stop</Button >}
                {!hasActiveTests && <Button onClick={handleToggleStart} size="small" color="primary" variant="contained" disabled={hasActiveServices}>Start</Button >}
            </div>

            <div className="flex-col">
                {context.utilities.data.filter(u => u.id_pedestal === props.id_pedestal).map((u, idx) => (
                    <PedestalUtility key={idx} utility={u} start={start} stop={stop} pause={pause} resume={resume} active={hasActiveTests} />
                ))}
            </div>

        </React.Fragment>
    )
}
export default PedestalUtilities;