import { Typography, Avatar, Divider, CircularProgress, Switch, Button, Tooltip } from '@mui/material';
import React, {useState, useContext, useEffect} from 'react';
import images from '../assets/imgs';
import API from '../global/api';
import globalContext from '../context/globalContext';
import PedestalUtilityTest from './pedestal-utility-test';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PersonIcon from '@mui/icons-material/Person';
import WarningIcon from '@mui/icons-material/Warning';

const PedestalUtility = props => {

    const context = useContext(globalContext);
    const [selected, setSelected] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const isPaused = () => {
        return context.paused.data.findIndex(p => p.id_utility === props.utility.id_utility && p.resumed === null) > -1;
    }

    const hasService = () => {
        return context.services.data.findIndex(s => s.id_utility === props.utility.id_utility && s.end_time === null) > -1;
    }


    //
    // Monitor the start button being pressed
    //
    useEffect(() => {

        if(props.start > 0 && selected)
            handleStartTest();

    }, [props.start])
    

    useEffect(() => {

        if(props.stop > 0 && selected)
            handleStopTest();

    }, [props.stop])


    //
    // Monitor the pause button being pressed
    //
    useEffect(() => {

        if(props.pause > 0)
            handlePauseService();

    }, [props.pause])


    //
    // Monitor the resume button being pressed
    //
    useEffect(() => {

        if(props.resume > 0)
            handleResumeService();

    }, [props.resume])




    const serviceTypeIcon = () => {
        switch(props.utility.id_service_type){
            case 1:
                return images.Icons_Electric_Icon;
            case 2:
                return images.Icons_Water_Icon;
            default:
                return null;
        }
    }


    const handleStartTest = async () => {

        try {

            setLoading(true);
            setError(null);

            let mins = 5;
            const localMins = localStorage.getItem('bvtt-timer');
            if(localMins !== null){
                const parsedMins = parseInt(JSON.parse(localMins));
                if(!isNaN(parsedMins))
                    mins = parsedMins;
            }

            let stop_on_pass = false;
            const localSop = localStorage.getItem('bvtt-sop');
            if(localSop !== null)
                stop_on_pass = Boolean(JSON.parse(localSop));


            // Create a test
            let newTest = {
                id_organisation: props.utility.id_organisation,
                id_utility: props.utility.id_utility,
                identity: props.utility.identity,
                id_session: null,
                id_service_type: props.utility.id_service_type,
                is_paused: false,
                started: new Date(),
                ended: null,
                vending: false,
                meter: false,
                unplug_latch: false,
                breaker: false,
                mins: mins,
                stop_on_pass: stop_on_pass
            }

            // Add the test to the tests state
            //context.testUpdate(newTest);

            // Start the test
            const data = await API.req(`/test/start`, 'POST', newTest);

            if(data.success === true){
            
                // Update the test with the session id
                context.testUpdate(data.result);

            } else {
                if(typeof data?.msg?.response?.data?.error?.message !== 'undefined')
                    setError(data?.msg?.response?.data?.error?.message);
                else
                    setError('An error occured starting the test');
            }
            
        } catch (error) {
            setError('An error occured starting the test');
        } finally {
            setLoading(false);
        }

    }


    const handleStopTest = async () => {

        const test = context.tests.data.find(t => t.identity === props.utility.identity);

        if(typeof test !== 'undefined' && test.ended === null && test.id_session !== null){

            try {
                    setLoading(true);
                    setError(null);

                    // Stop the test
                    const data = await API.req(`/test/stop/${test.id_session}`, 'POST', {});

                    if(data.success === true){
            
                        // Update the test with the session id
                        context.testUpdate(data.result);
        
                    }
                
            } catch (error) {
                setError('An error occured stopping the test');
            } finally {
                setLoading(false);
            }

        }

    }


    


    const handlePauseService = async () => {

        try {


            setError(null);
            const service = context.services.data.find(s => s.id_utility === props.utility.id_utility);

            if(typeof service !== 'undefined'){

                setLoading(true);

                // Start the test
                const data = await API.req(`/service/pause/${service.id_service}`, 'GET');

                if(data.success === true){
            
                    // Update the test with the session id
                    context.pausedUpdate(data.result);
    
                }

            }
            
        } catch (error) {
            console.log(error);
            setError('An error occured pausing the service');
        } finally {
            setLoading(false);
        }

    }


    


    const handleResumeService = async () => {

        try {


            setError(null);
            const pausedService = context.paused.data.find(p => p.id_utility === props.utility.id_utility && p.resumed === null);

            if(typeof pausedService !== 'undefined'){

                setLoading(true);

                // Start the test
                const data = await API.req(`/service/resume/${pausedService.id_paused_service}`, 'GET');

                if(data.success === true){
            
                    // Update the test with the session id
                    context.pausedUpdate(data.result);
    
                }

            }
            
        } catch (error) {
            setError('An error occured resuming the service');
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {

        const loadUtility = async () => {

            try {

                setLoading(true);
                setError(null);

                // Get the utility
                const data = await API.req(`/utility/${props.utility.id_utility}`, 'GET');
                if(data.success){
                    // Update the utility
                    context.utilityUpdate(data.result);
                }            
                
            } catch (error) {
                setError('An error occured loading the utility');
            } finally {
                setLoading(false);
            }

        }
        loadUtility();

    }, [])


    return (
        <React.Fragment>

                <Divider />

                <div className="flex-row align-center">

                    <Avatar src={serviceTypeIcon()} sx={{width: 28, height: 28}} />

                    <div className="flex-col flex gap-5">

                        <div className="flex-row align-center">
                            <Typography variant="body2">{props.utility.identity}</Typography>
                            {isPaused() && <PauseCircleOutlineIcon sx={{color: 'error.main', fontSize: 18}} />}
                            {hasService() && <PersonIcon sx={{color: 'error.main', fontSize: 18}} />}
                            <div className="flex"></div>
                            {loading && <CircularProgress size={24} />}
                            {(!loading && !props.active) && <Switch checked={selected} onChange={() => setSelected(!selected)} size="small" />}
                            {error && <Tooltip title={error}><WarningIcon color="error" /></Tooltip>}
                        </div>

                        <PedestalUtilityTest utility={props.utility} />

                    </div>
                </div>

        </React.Fragment>
    )

}
export default PedestalUtility;